// 海offer
import request from "../utils/request";
// 获取
export async function getJobList(params) {
  try {
    return await request({
      url: `/job/website/getJobList`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function jobCollectDelivered(id, data) {
  try {
    return await request({
      url: `/job/website/collectDelivered/${id}`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

export async function getCompanyJob(id, params) {
  try {
    return await request({
      url: `/job/website/getCompanyJobList/${id}`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function getCompanyDetail(id) {
  try {
    return await request({
      url: `/job/website/getCompanyOne/${id}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function getMyJob(params) {
  try {
    return await request({
      url: `/job/website/myJobWebsite`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function deleteOffer(id) {
  try {
    return await request({
      url: `/job/website/deleteCollectDelivered/${id}`,
      method: "delete",
    });
  } catch (error) {
    return {};
  }
}

export async function getCompanyList(params) {
  try {
    return await request({
      url: `/job/website/getCompanyList`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function getOfferList(params) {
  try {
    return await request({
      url: `/job/website/getUserJobList`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function getNoLoginOfferList(params) {
  try {
    return await request({
      url: `/job/website/getUserJobListNotLogin`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

export async function deleteDelivered(id) {
  try {
    return await request({
      url: `/job/website/deleteCollectDelivered/${id}`,
      method: "delete",
    });
  } catch (error) {
    return {};
  }
}

export async function getCompanyJobListNotLogin(id, params) {
  try {
    return await request({
      url: `/job/website/getCompanyJobListNotLogin/${id}`,
      method: "get",
      params
    });
  } catch (error) {
    return {};
  }
}
