<template>
  <div class="offer-oversea">
    <div
      class="offer-banner"
      :style="{ backgroundImage: 'url(' + img + ')' }"
    ></div>
    <div class="container">
      <div class="offer-main">
        <div class="custom-tabs">
          <div class="tab-content">
            <div class="offer-pane">
              <!--              <div class="offer-tip">-->
              <!--                实时追踪海内外公司职位信息，做中国人的海内外求职桥梁！提供最实时最全面的求职咨询！-->
              <!--              </div>-->
              <div class="offer-form">
                <div class="company-cell company-cell-card">
                  <img :src="companyDetail.avatar" alt="" />
                  <div class="flex-col">
                    <div class="c-title">
                      <span class="company-cell-title">
                        {{
                          companyDetail.country == 1
                            ? companyDetail.name
                            : companyDetail.nameEn
                        }}
                        {{
                          companyDetail.country == 1
                            ? companyDetail.nameEn
                            : companyDetail.name
                        }}
                      </span>
                      <el-tag size="small">
                        {{
                          companyDetail.country == 1
                            ? "中国"
                            : companyDetail.country == 2
                            ? "美国"
                            : companyDetail.country == 3
                            ? "加拿大"
                            : companyDetail.country == 4
                            ? "澳大利亚"
                            : companyDetail.country == 5
                            ? "英国"
                            : companyDetail.country == 6
                            ? "德国"
                            : companyDetail.country == 7
                            ? "法国"
                            : companyDetail.country == 8
                            ? "瑞士"
                            : companyDetail.country == 9
                            ? "爱尔兰"
                            : companyDetail.country == 10
                            ? "日本"
                            : companyDetail.country == 11
                            ? "荷兰"
                            : companyDetail.country == 12
                            ? "西班牙"
                            : companyDetail.country == 13
                            ? "俄罗斯"
                            : ""
                        }}</el-tag
                      >
                    </div>
                    <div class="c-items">
                      {{ companyDetail.description }}
                    </div>
                    <div class="list-tags" v-if="companyDetail.tagsName">
                      <span
                        class="item"
                        v-for="tag in companyDetail.tagsName.split(',')"
                        >{{ tag }}</span
                      >
                    </div>
                  </div>
                  <div class="company-right" style="margin-top: 7px">
                    <div
                      class="list-link-solid"
                      @click="jumpSite(companyDetail.href)"
                      style="cursor: pointer"
                    >
                      <img src="@/assets/images/offer/e-blue.png" alt="" /><span
                        >查看官网</span
                      >
                    </div>
                    <div class="c-items">
                      <el-tag size="small">{{ companyDetail.scope }}</el-tag
                      ><span>{{
                        companyDetail.scale == 1
                          ? "世界500强"
                          : companyDetail.scale == 2
                          ? "巨头公司 10k+人"
                          : companyDetail.scale == 3
                          ? "大型公司 1k-10k人"
                          : companyDetail.scale == 4
                          ? "小型公司 1000人以下"
                          : ""
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="offer-group">
                  <div class="offer-rows">
                    <div class="offer-label">公司简介：</div>
                    <div
                      class="flex-col rich-text"
                      v-html="companyDetail.brief"
                    ></div>
                  </div>
                  <div class="offer-rows" v-if="companyDetail.intelligence">
                    <div class="offer-label">内部消息：</div>
                    <div
                      class="flex-col rich-text"
                      v-html="companyDetail.intelligence"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="offer-list">
        <div
          v-for="(item, index) in offerList"
          :key="index"
          class="offer-list-card"
        >
          <div class="list-card-left">
            <div class="list-card-title" @click="jumpSite(item.href)">
              <div class="list-job-name">{{ item.jobName }}</div>
              <span class="price">{{ item.jobSalary }}</span>
            </div>
            <div
              class="list-tags"
              v-if="item.jobTags && item.jobTags.length > 0"
            >
              <span class="item" v-for="tag in item.jobTags.split(',')">{{
                tag
              }}</span>
            </div>
          </div>
          <div class="list-link" @click="jumpSite(item.href)">
            <img src="../../../assets/images/offer/e.png" alt="" /><span
              >官网直投</span
            >
          </div>
          <div class="list-card-info">
            <div class="company-cell">
              <img :src="item.logo" alt="" />
              <div class="flex-col">
                <div class="c-title">
                  {{ item.country == 1 ? item.name : item.nameEn }}
                </div>
                <div class="c-items">
                  <div
                    v-if="item.intelligence"
                    size="small"
                    style="
                      padding-right: 4px;
                      padding-left: 2px;
                      font-size: 12px;
                    "
                  >
                    内部消息<span style="color: #e9e9ec">｜</span>
                  </div>
                  <div
                    size="small"
                    style="
                      padding-right: 1px;
                      font-size: 12px;
                      /*border-right: 1px solid #666;*/
                    "
                  >
                    {{ item.scope }}&nbsp;&nbsp;&nbsp;<span
                      style="color: #e9e9ec"
                      >｜</span
                    >
                  </div>
                  <div
                    size="small"
                    style="
                      padding-right: 0px;
                      padding-left: 0px;
                      font-size: 12px;
                    "
                  >
                    {{
                      item.scale == 1
                        ? "世界500强"
                        : item.scale == 2
                        ? "巨头公司"
                        : item.scale == 3
                        ? "大型公司"
                        : item.scale == 4
                        ? "小型公司"
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-card-right">
            <div class="list-action">
              <div
                class="list-action-item list-action-flay"
                :class="{ active: item.deliver }"
                @click="toggleDelivered(item)"
              >
                <div class="ac-box-fly">
                  <img
                    :src="
                      item.deliver
                        ? require('../../../assets/images/offer/telegram-w.png')
                        : require('../../../assets/images/offer/telegram.png')
                    "
                    alt=""
                  />
                </div>
                <div class="ac-label">
                  {{ item.deliver ? "已投递" : "标记已投递" }}
                </div>
              </div>
              <div
                class="list-action-item list-action-star"
                :class="{ active: item.collect }"
                @click="toggleStarred(item)"
              >
                <div class="ac-box-star">
                  <span class="iconfont icon-toudi"></span>
                </div>
                <div class="ac-label">
                  {{ item.collect ? "已加入" : "加入待投递" }}
                </div>
              </div>
            </div>
            <div class="list-time">
              {{
                getTimeDifferenceDescription(formatTime(item.updateTime))
              }}发布
            </div>
          </div>
        </div>
      </div>
      <div class="offer-foot" v-if="total != 0">
        <el-pagination
          :current-page.sync="current"
          background
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChangePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCompanyJob,
  getCompanyDetail,
  jobCollectDelivered,
  getCompanyJobListNotLogin,
  deleteDelivered,
} from "@/service/offer";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      current: 1,
      total: 0,
      pageSize: 10,
      img: require("@/assets/images/offer/offer-banner.jpg"),
      companyDetail: {},
      offerList: [],
    };
  },
  created() {},
  computed: mapState(["userInfo"]),
  mounted() {
    this.getCompanyJob();
    getCompanyDetail(this.$route.query.id).then((res) => {
      this.companyDetail = res.result;
    });
  },
  methods: {
    jumpSite(url) {
      window.open(url);
    },
    getCompanyJob() {
      if (!this.userInfo || !this.userInfo.id) {
        getCompanyJobListNotLogin(this.$route.query.id, {
          current: this.current,
          size: this.pageSize,
        }).then((res) => {
          this.offerList = res.result.records;
          this.total = res.result.total;
        });
      } else {
        getCompanyJob(this.$route.query.id, {
          current: this.current,
          size: this.pageSize,
        }).then((res) => {
          this.offerList = res.result.records;
          this.total = res.result.total;
        });
      }
    },
    handleCurrentChangePage(val) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.handleCurrentChange(val);
    },
    handleSizeChange(val) {
      this.size = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getCompanyJob();
    },
    toggleDelivered(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return this.$message.info("请先登录");
      }
      this.$message.closeAll();
      if (item.deliver) {
        deleteDelivered(item.id).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            return this.$message.success("已取消投递！");
          }
        });
      } else {
        jobCollectDelivered(item.id, {
          state: 1,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            this.$message.success("标记为投递成功！");
          }
        });
      }
    },
    formatTime(utcTimeString) {
      const isoTimeString = utcTimeString + "Z";

      // 创建一个Date对象，它将根据浏览器的本地时区自动调整
      const date = new Date(isoTimeString);
      // 使用Intl.DateTimeFormat来格式化日期时间
      // 这里可以根据需要调整选项，例如日期和时间部分的展示方式
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 使用24小时制，根据需要可以设置为true使用12小时制
      };

      // 格式化输出本地时区的日期时间
      const formattedDateTime = new Intl.DateTimeFormat(
        "default",
        options
      ).format(date);

      return formattedDateTime;
    },
    getTimeDifferenceDescription(timeString) {
      const givenTime = new Date(timeString);
      const currentTime = new Date();

      if (isNaN(givenTime)) {
        throw new Error("无效的时间字符串");
      }

      const timeDifference = currentTime - givenTime;
      const millisecondsInAnHour = 1000 * 60 * 60;
      const millisecondsInADay = millisecondsInAnHour * 24;
      const millisecondsInAWeek = millisecondsInADay * 7;

      if (timeDifference < millisecondsInAnHour) {
        return "1小时之前";
      } else if (timeDifference < millisecondsInADay) {
        const hours = Math.floor(timeDifference / millisecondsInAnHour);
        return `${hours}小时前`;
      } else if (timeDifference < millisecondsInAWeek) {
        const days = Math.floor(timeDifference / millisecondsInADay);
        return `${days}天前`;
      } else {
        return givenTime.toLocaleDateString();
      }
    },
    toggleStarred(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return this.$message.info("请先登录");
      }
      this.$message.closeAll();
      if (item.deliver) {
        // return this.$message.info("已投递此职位！");
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
          }
        });
      }
      if (item.collect) {
        deleteDelivered(item.id).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            return this.$message.success("已取消加入待投递！");
          }
        });
      } else {
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            this.$message.success("加入待投递成功！");
          }
        });
      }
    },
  },
};
</script>
<style scoped="scoped" lang="less">
@import url("../../../assets/css/haioffer.css");
.offer-list {
  min-height: 600px;
}
.list-card-info:hover .c-title {
  color: black;
}
.offer-list-card {
  .c-items {
    margin-top: 14px;
  }
}
.list-card-left {
  width: 450px;
}
::v-deep .rich-text {
  img {
    max-width: 100% !important;
  }
}
</style>
